import React, { useEffect } from 'react';
import { BsInstagram, BsLinkedin, BsPinterest, BsYoutube } from 'react-icons/bs';

import DropDown from 'src/components/dropdown/DropDown';
import { ErrorToast, SuccessToast } from 'src/helpers/Toast';
import { ApiGetNoAuth, ApiPostNoAuth } from 'src/services/http-service';

const index = () => {
  const [state, setstate] = React.useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [dropdownOpen, setdropdownOpen] = React.useState<boolean>(false);
  const [subjectValue, setsubjectValue] = React.useState<any>('Select Subject');
  const [submitClick, setSubmitClick] = React.useState<boolean>(false);
  const [prog, setProg] = React.useState<any>(false);
  const [data, setData] = React.useState([]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setstate({
      ...state,
      [name]: value
    });
  };

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };

  function validateEmail(email: string) {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const sendConatctUs = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitClick(true);

    if (!state.name) {
      return; // Stop form submission
    }
    if (!state.email) {
      return; // Stop form submission
    }
    if (subjectValue === 'Select Subject') {
      return; // Stop form submission
    }
    if (!state.message) {
      return; // Stop form submission
    }
    if (state.name.length > 50) {
      return; // Stop form submission
    }
    if (!validateEmail(state.email)) {
      return; // Stop form submission
    }
    if (state.message.length > 200) {
      return; // Stop form submission
    }
    const body = { ...state, subject: subjectValue };

    ApiPostNoAuth('user/contact_us', body)
      .then((res: any) => {
        SuccessToast(res?.data?.message);
        setProg(true);
        setstate({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setsubjectValue('Select Subject');
      })
      .catch(async (err: any) => {

        ErrorToast(err?.data?.message);
      });

  };

  useEffect(() => {
    ApiGetNoAuth('user/subject').then((res: any) => {
      console.log(res)
      setData(res?.data?.data);
    });

  }, []);

  const handleOpenCategry = (e: any) => {
    setsubjectValue(e.target.innerHTML);
  };
  return (
    <div className="primary-content-area container content-padding">
      <div className="page-title">
        <h2>
          <span className="gradient-text">Get In Touch</span> With Us
        </h2>
      </div>
      <div className="grid-right-sidebar">
        <div className="main-content-area">
          <form
            className="cryptoki-form"
            id="contact-form"

          >
            <input type="hidden" name="recaptcha_site_key" value="" />
            <input type="hidden" name="recaptcha_token" value="" />
            <div className="form-group row">
              <div className="form-field col-md-6">
                <label htmlFor="name">Your name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={state?.name}
                  onChange={handleChange}
                />
                {!prog && !state?.name && submitClick && (
                  <p className="text-danger bold font-size=22">
                    Name is required
                  </p>
                )}
                {!prog && state?.name.length > 50 && submitClick && (
                  <p className="text-danger bold font-size=22">
                    Name exceeds character limit. Limit to 50 characters or less.
                  </p>
                )}
              </div>
              <div className="form-field col-md-6">
                <label htmlFor="email">Your email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={state?.email}
                  onChange={handleChange}
                />
                {!prog && !state?.email && submitClick && (
                  <p className="text-danger bold font-size=22">
                    Email is required
                  </p>
                )}
                {!prog && state?.email && !validateEmail(state?.email) && submitClick && (
                  <p className="text-danger bold font-size=22">
                    Email is not valid
                  </p>
                )}
              </div>
            </div>
            <div className="form-field">
              <label htmlFor="subject">Subject</label>
              <DropDown
                dropdownOpen={dropdownOpen}
                toggle={toggle}
                categoryValue={subjectValue}
                array={data}
                onClick={handleOpenCategry}
              />
              {!prog && (subjectValue === 'Select Subject') && submitClick && (
                <p className="text-danger bold font-size=22">
                  Subject is required
                </p>
              )}
              {/* <label htmlFor="subject">Subject</label>
                            <select 
                            // className="select-nice" 
                            name="subject" 
                            id="subject" 
                            onChange={handleChange}
                            >
                              {data.map((e : any) => {
                                return (
                                  <>
                                  <option value={e?.subject}>{e?.subject}</option>
                                  </>
                                )
                              })
                              }
                            </select> */}
            </div>
            <div className="form-field comment-area">
              <label htmlFor="message">Your comment</label>
              <textarea
                id="message"
                name="message"
                className="comment-form message"
                cols={30}
                rows={10}
                value={state?.message}
                onChange={handleChange}
              ></textarea>
              {!prog && !state?.message && submitClick && (
                <p className="text-danger bold font-size=22">
                  Message is required
                </p>
              )}
              {!prog && state?.message.length > 200 && submitClick && (
                <p className="text-danger bold font-size=22">
                  Message exceeds character limit. Limit to 200 characters or less.
                </p>
              )}
            </div>

            <button
              type="button"
              className="button btn btn-normal btn-dark waves-effect waves-button waves-float waves-light"
              data-badge="inline"
              onClick={sendConatctUs}
            >
              Send
            </button>
          </form>
        </div>
        <aside>
          <div className="widgets-list">
            <div className="widget widget-contact">
              <div className="widget-title h5">Business Inquiries</div>
              <div className="widget-body">
                <div className="email">

                  <div >
                    Support@unicornui.com
                  </div>
                </div>

              </div>
            </div>
            <div className="widget widget-contact">
              <div className="widget-title h5">Partnership Inquiries</div>
              <div className="widget-body">
                <div className="email">

                  <div>
                    Support@unicornui.com
                  </div>
                </div>
              </div>
            </div>
            <div className="widget widget-social">
              <div className="widget-title h5">Social Accounts</div>
              <div className="widget-body">
                <ul className="social-styled-list">
                  <li className="social-icon" onClick={() =>
                    window?.open(
                      'https://in.pinterest.com/unicornui_official/',
                      '_blank'
                    )
                  }>
                    <div className="contact-social-account">
                      <BsPinterest className="footerIcon" />
                      <div className='contact-social-account-name'>@unicornui_official</div>
                    </div>
                  </li>
                  <li
                    className="social-icon "
                    onClick={() =>
                      window?.open('https://www.youtube.com/@unicornui', '_blank')
                    }
                  >
                    <div className='contact-social-account'>
                      <BsYoutube className="footerIcon " />
                      <div className='contact-social-account-name'>@unicornui</div>

                    </div>
                  </li>
                  <li
                    className="social-icon"
                    onClick={() =>
                      window?.open(
                        'https://www.instagram.com/unicornui.official/',
                        '_blank'
                      )
                    }
                  >
                    <div className="contact-social-account">
                      <BsInstagram className="footerIcon " />
                      <div className='contact-social-account-name'>@unicornui_official</div>
                    </div>
                  </li>
                  <li
                    className="social-icon "
                    onClick={() =>
                      window?.open(
                        'https://www.linkedin.com/company/86402313/admin/',
                        '_blank'
                      )
                    }
                  >
                    <div className="contact-social-account">
                      <BsLinkedin className="footerIcon " />
                      <div className='contact-social-account-name'>@unicornui</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
      </div>
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default index;
