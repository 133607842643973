import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Pagination } from '@material-ui/lab';
import DialogContent from '@material-ui/core/DialogContent';

import Aside from '../../components/aside';
import Loader from 'src/components/loader';
import UseNav from 'src/Hooks/Header/UseNav';
import { ApiGet } from 'src/helpers/API/ApiData';
import NoDataPage from 'src/components/noDataFound/No-data';
import { ApiPost, Bucket } from 'src/services/http-service';
import { ErrorToast, SuccessToast } from 'src/helpers/Toast';
import { profileUpdate } from 'src/redux/reducer/profileUpdateSlice';


const Payouts = () => {
  const navData = UseNav();
  const dispatch = useDispatch();
  const [profileData, setProfileData] = React.useState<any>([]);
  const [data, setdata] = React.useState([]);
  
  const [dropdownOpen, setdropdownOpen] = React.useState<boolean>(false);
  const [Year, setYear] = React.useState<any>('2022');
  const [YearValue, setYearValue] = React.useState<any>('Select Year');
  const [loading, setloading] = React.useState<boolean>(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [totalpage, settotalpage] = useState(0);
  const [paypalData, setPaypalData] = useState(0);
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [balanceData, setBalanceData] = useState<any>({});
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [disbut, setdisbut] = useState(false);

  
  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };
  const handleonchnagespagination = (e: any) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
  };
  const onPaginationChange = (e: any, i: any) => {
   
    setcurrentpage(i);
  };
  const JOSN = [
    {
      id: 1,
      createdAt: '11/04/2024',
      totalAmount: 'Regular',
      paymentStatus: 13212
    },
    {
      id: 2,
      createdAt: '04/06/2024',
      totalAmount: 'Commercial',
      paymentStatus: 0
    },
    {
      id: 3,
      createdAt: '01/02/2024',
      totalAmount: 'Extended',
      paymentStatus: 1
    },
    {
      id: 4,
      createdAt: '05/01/2024',
      totalAmount: 'Regular',
      paymentStatus: 1
    },
    {
      id: 5,
      createdAt: '11/02/2024',
      totalAmount: 'Extended',
      paymentStatus: 0
    }
  ];
  const handleYear = (e: any) => {
    setYear(e.target.value);
    setYearValue(e.target.innerHTML);
  };
  
  React.useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  const YearArray = [
    {
      _id: '1',
      name: '2022'
    },
    {
      _id: '2',
      name: '2023'
    },
    {
      _id: '3',
      name: '2024'
    },
    {
      _id: '4',
      name: '2025'
    },
    {
      _id: '5',
      name: '2026'
    }
  ];
  const handleSubmitEmail = async (e: any) => {
    e.preventDefault();
    let body = {
      email: email
    };
    if (email) {
      ApiPost('/verifedemail', body)
        .then(async (res: any) => {
          getProfile();
          setEmail('');
          SuccessToast(res?.message);
          setloading(false);
          setShow(false);
        })
        .catch((e) => {
          setloading(false);
        });
    } else {
      ErrorToast('Please enter email!');
    }
  };
  const handleSubmitAmount = async (e: any) => {
    e.preventDefault();
    setdisbut(true);
    let body = {
      amount: Number(amount)
    };
    let minwith:any
    await ApiGet(`/comman/get_item`).then((res:any) => {
     
      minwith = res.data.minwithdraw
    });

    if (amount && Number(amount) >= minwith) {
      if (parseFloat(amount) <= balanceData?.balance) {
        ApiPost('/withdrawrequest', body)
          .then((res: any) => {
            
            SuccessToast(res?.message);
            getAmount();
            setloading(false);
            setModal(false);
            getData();
          
            setAmount('')
            setdisbut(false);
            window.location.reload();

          })
          .catch((e) => {
            setloading(false);
          });
      } else {
    setdisbut(false);

        ErrorToast("Insufficient balance!");
      }
    } else {
    setdisbut(false);

      ErrorToast(`Please enter Valid amount,Minimum withdrawal: $${minwith}.`);
    }
  };
  const getAmount = async() => {
    await ApiGet('/getbalance')
      .then((res: any) => {
        if (res?.data?.balance || res?.data?.balance==0) {
          setBalanceData(res?.data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getProfile = async () => {
    await ApiGet('/profile').then((res: any) => {
  
      setProfileData(res?.data);
      let obj = {
        ...res?.data,
        userType: navData?.parsedLoginData?.userType,
        token: navData?.parsedLoginData?.token
      };
      localStorage.setItem('logindata', JSON.stringify(obj));
      dispatch(profileUpdate(obj));
      setPaypalData(res?.data?.payoutemail === null ? 0 : 1);
    });
  };
  const getData = () => {
    setloading(true);
    const body = {
      search: '',
      page: currentpage,
      limit: pagesize,
      year: YearValue
    };
    
    ApiPost('/getwallet', body)
      .then((res: any) => {
     
        setdata(res?.data?.data);
        settotalpage(res?.data?.state?.page_limit);
        setloading(false);
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        setloading(false);
      });
  };
  React.useEffect(() => {
    getData();
  }, [YearValue, currentpage, pagesize]);
  useEffect(() => {
    getAmount();
    getProfile();
  }, []);
  return (
    <>
      <div className="primary-content-area container content-padding grid-left-sidebar">
        <Aside />
        <div className="main-content-area">
          <div className="page-title-section">
            <h2>
              <span className="gradient-text">Payouts</span>
            </h2>
          </div>
          {/* <div className="filterable-bar">
            <form id="creators-filter-form">
              <div style={{ width: '180px', height: '43px' }}>
                <DropDown
                  dropdownOpen={dropdownOpen}
                  toggle={toggle}
                  categoryValue={YearValue}
                  array={YearArray}
                  onClick={handleYear}
                />
              </div>
              <div className="filter-button">
                <button className="btn btn-normal btn-dark waves-effect waves-button waves-float waves-light">
                  Filter
                </button>
              </div>
            </form>
          </div> */}
          <div className="upload-row">
            <div className="upload-column">
              <div className="statement">
                <h5>Payments History</h5>
                {loading ? (
                  <Loader />
                ) : data?.length !== 0 ? (
                  <div>
                    <table className="content-table">
                      <thead>
                        <tr>
                          <th scope="col" className="heading-label">
                            Date
                          </th>
                          <th scope="col" className="heading-label">
                            Description
                          </th>
                          <th scope="col" className="heading-label">
                            Type
                          </th>
                          <th scope="col" className="heading-label">
                            Amount
                          </th>
                          {/* <th scope="col" className="heading-label">
                          Reference ID
                        </th> */}
                          <th scope="col" className="heading-label">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((e: any) => {
                          return (
                            <>
                              <tr>
                                <td data-label="Date">
                                  <div className="date">
                                    {moment(e?.createdAt).format('MMM DD,YYYY')}
                                  </div>
                                </td>
                                <td
                                  data-label="Method Item details"
                                  className="statement"
                                >
                                  <div
                                    className="item-title gradient-text"
                                    style={{ width: '200px' }}
                                  >
                                    {e?.title}
                                  </div>
                                  <div>
                                    Refrence -{' '}
                                    <span className="gradient-text">
                                      #
                                      {Math?.round(
                                        new Date(e?.createdAt).getTime() / 1000
                                      )}
                                    </span>
                                  </div>
                                  {/* <div className="license-details">
                                Reference ID - #CRKT12354
                              </div> */}
                                </td>
                                <td data-label="Amount" className="stat-value">
                                  {e?.licenseType == 1
                                    ? 'Commercial'
                                    : e?.licenseType == 2
                                    ? 'Extended'
                                    : 'Regular'}
                                </td>
                                <td data-label="Amount" className="stat-value">
                                  $
                                  {(Math.round(e?.price * 100) / 100).toFixed(
                                    2
                                  )}
                                </td>
                                {/* <td data-label="price" className="stat-value">
                                #
                                {Math?.round(
                                  new Date(e?.createdAt).getTime() / 1000
                                )}
                              </td> */}
                                <td
                                  data-label="price"
                                  className={
                                    e?.isstatus === 0
                                      ? 'text-success stat-value pe-3'
                                      : e?.isstatus === 1
                                      ? 'stat-value pe-3'
                                      : e?.isstatus === 2
                                      ? 'text-danger stat-value pe-3'
                                      : 'text-primary stat-value pe-3' + ''
                                  }
                                >
                                  {e?.isstatus === 0
                                    ? 'Credited'
                                    : e?.isstatus === 1
                                    ? 'Requested'
                                    : e?.isstatus === 2
                                    ? 'Rejected'
                                    : 'Withdrawal'}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    {totalpage > 1 && (
                      <div className="d-flex justify-content-between pt-10">
                        <div className="my-2">
                          <Pagination
                            count={totalpage}
                            page={currentpage}
                            onChange={onPaginationChange}
                            variant="outlined"
                            shape="rounded"
                            className="pagination_"
                          />
                        </div>
                        <div className="my-2">
                          <div className="d-flex align-items-center pagination-drpdown">
                            <select
                              className="form-control pagination-drpdown1 dropdownPage"
                              id="kt_datatable_search_status"
                              onChange={(e) => handleonchnagespagination(e)}
                              value={pagesize}
                            >
                              {/* <option value={1}>1</option> */}
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <NoDataPage
                    title="Result Not Found"
                    image="/Image/Icons/NotFound.png"
                    desc="Please try again with another keywords or maybe use generic term"
                    status={true}
                    btnName="Go to Home"
                    path="/"
                  />
                )}
              </div>
            </div>
            <div className="upload-column">
              <div className="payment-method-section">
                <h5>Payment Method</h5>
                {paypalData === 1 && (
                  <div className="confirmed-section py-4">
                    <div className="fs-2 mb-2 fw-boldest lh-sm">
                      {balanceData?.balance
                        ? '$' +
                            (
                              Math.round(balanceData?.balance * 100) / 100
                            ).toFixed(2) || 0
                        : '$' + 0}
                    </div>
                    <div className="mb-3">Available balance to withdraw</div>
                    <button
                      className="btn btn-normal btn-dark create-collection waves-effect waves-button waves-float waves-light hower-effect w-100"
                      onClick={() => setModal(true)}
                    >
                      Get Paid
                    </button>
                  </div>
                )}
                {paypalData === 0 && (
                  <form className="confirmed-section" id="payment-method-form">
                    <div className="payment-method">
                      <label htmlFor="paypal" className="font-bold">
                        Paypal
                      </label>
                      <div className="lh-base mb-4">
                      Enter your PayPal account to receive payment securely and conveniently.
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Add Your Pay Pal Account..."
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-normal btn-dark create-collection waves-effect waves-button waves-float waves-light hower-effect w-100"
                     
                      onClick={(e) => {
                        handleSubmitEmail(e);
                      }}
                    >
                      Confirm Method
                    </button>
                  </form>
                )}

                {paypalData === 1 && (
                  <div className="confirmed-section">
                    <div className="d-flex align-items-baseline justify-content-between">
                      <div className="small-title">Confirmed Method</div>
                      <span
                        className="edit ms-2"
                        onClick={() => {
                          setEmail(profileData?.payoutemail);
                          setShow(true);
                        }}
                      >
                        <SVG
                          src="Image/Icons/EditPost.svg"

                          // className="iconsSize"
                        />
                      </span>
                    </div>
                    <div className="confirmed-info">
                      <a
                        href="mailto:dexterstark@fakepay.com"
                        className="green bold"
                      >
                        {profileData?.payoutemail}
                      </a>

                      <div className="confirmed-comment">
                        <span className="bold">Paypal</span> - Confirmed on Nov
                        17, 2020
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ins className="adsbygoogle"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
          data-ad-client="ca-pub-9953424002161141"
          data-ad-slot="4560984194"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
      <Dialog
        maxWidth="sm"
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="curveImage" id="style-1">
          <Row style={{ direction: 'rtl' }}>
            <div className="cursor-pointer">
              <CgClose onClick={() => setModal(false)} />
            </div>
          </Row>
          <form className="confirmed-section mb-4" id="payment-method-form">
            <div className="payment-method">
              <label htmlFor="paypal" className="font-bold">
                Paypal
              </label>
              <div className="lh-base mb-4">
              Enter the amount and get paid instantly!
              </div>
              <div className="form-field">
                <input
                  type="number"
                  className="form-control "
                  placeholder="Enter withdraw price"
                  onChange={(e) => setAmount(e.target.value)}
                  min={0}
                  value={amount}
                />
              </div>
            </div>
            <button
              className="btn btn-normal btn-dark create-collection waves-effect waves-button waves-float waves-light hower-effect w-100"
              type="submit"
              disabled={disbut}
              onClick={(e) => {
                handleSubmitAmount(e);
              }}
            >
              Confirm Method
            </button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="curveImage" id="style-1">
          <Row style={{ direction: 'rtl' }}>
            <div className="cursor-pointer">
              <CgClose onClick={() => setShow(false)} />
            </div>
          </Row>
          <form className="confirmed-section mb-4" id="payment-method-form">
            <div className="payment-method">
              <label htmlFor="paypal" className="font-bold">
                Paypal
              </label>
              <div className="lh-base mb-4">
              Enter your PayPal account to receive payment securely and conveniently.
              </div>
              <div className="form-field">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Add Your Pay Pal Account..."
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </div>
            <button
              className="btn btn-normal btn-dark create-collection waves-effect waves-button waves-float waves-light hower-effect w-100"
              type="submit"
              onClick={(e) => {
                handleSubmitEmail(e);
              }}
            >
              Confirm Method
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Payouts;
