import React, { useEffect } from 'react'
import { AiOutlineMinus } from 'react-icons/ai';

import { ApiPost } from 'src/helpers/API/ApiData';

const index = () => {
  const [data, setData] = React.useState([])
  useEffect(() => {
    const body = {
      "search": "",
      "page": 1,
      "limit": 1000
    }
    ApiPost("/frequently_question_pagination", body).then((res: any) => {
      setData(res?.data?.frequentlyQuestionData)
    })
      .catch
  }, []);
  return (
    <div className="main">
      <div className="primary-content-area section-medium content-padding">
        <div className="page-title text-center">
          <h2>Frequently <span className="gradient-text">Asked Questions</span></h2>
        </div>
        <div className="accordion">
          {data?.map((e: any, i: any) => {
            return (
              <div className="accordion-card question" key={i} style={{ paddingBottom: "21px" }}>
                <div className="card-header questionHeader cursor-pointer" data-bs-toggle="collapse" data-bs-target={`#collapseOne${i}`}>
                  <div className=' card-button'>
                    <div className="card-title">{e?.question}</div>
                    <div className="icon-box">
                      <svg id={`collapseOne${i}`} className="crumina-icon plus-icon show" style={{ left: "-11px" }}>
                        <AiOutlineMinus />
                      </svg>
                      <svg className="crumina-icon minus-icon">
                        <AiOutlineMinus />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="answerShow">
                  <div id={`collapseOne${i}`} className="accordion-collapse collapse answerBody">
                    <div className="card-info answerInfo">{e?.answer}</div>
                  </div>
                </div>
              </div>
            )
          })
          }
        </div>
        <ins className="adsbygoogle"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
          data-ad-client="ca-pub-9953424002161141"
          data-ad-slot="4560984194"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
    </div>
  )
}

export default index