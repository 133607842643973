import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'src/components/loader';
// import ItemCard from 'src/components/itemCard';
const ItemCard = lazy(() => import("src/components/itemCard"));
import { ApiGet, ApiGetNoAuth, ApiPostNoAuth } from 'src/services/http-service';
import { getFilter } from 'src/redux/reducer/searchFilterSlice';
import { ApiPost } from 'src/helpers/API/ApiData';
import {
  isOpenModal,
  openModal,
  userProfile
} from 'src/redux/reducer/profileUpdateSlice';
import DropDown from 'src/components/dropdown/DropDown';
import NoDataPage from 'src/components/noDataFound/No-data';
import { getTitleFromPath } from 'src/helpers/Title/TitleFromPath';

let page = 1;
let subCategoryId = '';
let CategoryId = '';
let softwareId = '';
let isReloadUseEffect = false;
const ExploreItems = (props: any) => {
  const dispatch = useDispatch();
  const userProfileData = useSelector(userProfile);
  const nextPage = 1;
  const location: any = useLocation();
  const searchFilter = useSelector(getFilter);
  const [subcategoryValue, setsubcategoryValue] = React.useState<any>([]);
  const [subCategoryEnums, setsubCategoryEnums] = React.useState<any>([]);
  const [category, setcategory] = React.useState<any>([]);
  const [categoryValue, setcategoryValue] = React.useState<any>([]);
  const [postData, setPostData] = React.useState<any>([]);
  const [softwareData, saveSoftwareData] = React.useState<any>([]);
  const [postFirstData, setPostFirstData] = React.useState<any>();
  const [selectedCategory, setselectedCategory] = React.useState<any>([]);
  const [categoryId, setcategoryId] = React.useState<any>([]);
  const [subCategoryId1, setsubCategoryId] = React.useState<any>(
    []
  );
  const [softwareValue, setsoftwareValue] = React.useState<any>([]);
  const [selectedSubCategory, setselectedSubCategory] = React.useState<any>([]);
  const [selectedSoftware, saveSelectedSoftware] = React.useState<any>(
    softwareId ? [softwareId] : []
  );
  const [dropdownOpen, setdropdownOpen] = React.useState<boolean>(false);
  const [subdropdownOpen, setsubdropdownOpen] = React.useState<boolean>(false);
  const [softwaredropdownOpen, setsoftwaredropdownOpen] =
    React.useState<boolean>(false);
  const [dynamicTitle, setDynamicTitle] = React.useState<any>(
    'Unicorn UI - Where design begins!'
  );
  const [dynamicDescription, setDynamicDescription] = React.useState<any>(
    'Unicorn UI is a website for designers to find beautiful designs and graphics. Our goal is to offer the best high-quality content: UI Designs, UI kits, Logos, banner,icons, mockups.'
  );
  const [dynamicH1, setDynamicH1] = React.useState<any>('Explore Items');
  const [dynamicP, setDynamicP] = React.useState<any>('explore items');
  const [dynamicSubline, setDynamicSubline] = React.useState<any>('');

  // const subCategoryEnums = React.useMemo(() => {
  //   return category
  //     .filter((data: any) => data._id === categoryId)
  //     ?.map((values: any) => values.menu_sub_categories)[0];
  // }, [categoryId]);

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const subtoggle = () => {
    setsubdropdownOpen(!subdropdownOpen);
  };

  const softwaretoggle = () => {
    setsoftwaredropdownOpen(!softwaredropdownOpen);
  };

  const handleOpenCategry = (e: any) => {
    page = 1;

    // let flag = categoryValue?.includes(e.target.innerHTML);
    let flag = categoryValue?.find((v: any) => v?.value == e.target.value);

    if (!flag) {
      // setcategoryId(e.target.value);
      // handleCategoryChange(e.target.value);
      // setcategoryValue(e.target.innerHTML);

      setselectedCategory([...selectedCategory, e.target.value]);

      setcategoryId([...categoryId, e.target.value]);
      setcategoryValue((preValue: any) => {
        return [...preValue, e.target];
      });
    }
  };

  const handleOpensubCategry = (e: any) => {
    page = 1;

    // handleSubCategoryChange(e.target.value);
    let flag = subcategoryValue?.find((v: any) => v?.value == e.target.value);

    if (!flag) {
      setsubCategoryId([...subCategoryId1, e.target.value]);
      setsubcategoryValue((preValue: any) => {
        return [...preValue, e.target];
      });

      // setsubCategoryId(e.target.value);
      // setsubcategoryValue(e.target.innerHTML);
    }
  };
  // const handleSoftware = (e: any) => {
  //   // handleSubCategoryChange(e.target.value);
  //   setsoftwareId(e.target.value);
  //   setsoftwareValue(e.target.innerHTML);
  // };
  const handleSoftware = (e: any) => {
    page = 1;

    let flag = softwareValue?.find((v: any) => v?.value == e.target.value);

    if (!flag) {
      saveSelectedSoftware([...selectedSoftware, e.target.value]);
      setsoftwareValue((preValue: any) => {
        return [...preValue, e.target];
      });
    }
  };

  const getExploredDataWithAuth = async () => {
    let body = {
      limit: 20,
      page: 1,
      status: 'approve',
      search: searchFilter,
      software: selectedSoftware,
      categoryId: categoryId ? categoryId : [],
      subCategoryId: subCategoryId1 ? subCategoryId1 : []
    };
    await ApiPost(`/post`, body).then((res: any) => {
      // refetch();

      setPostData(res?.data?.post_data);
      setPostFirstData(res?.data?.state?.post_count);
      // navData?.getCount();
    });
  };
  // const post = {
  //   limit: 20,
  //   page: nextPage,
  //   status: 'approve',
  //   search: searchFilter,
  //   software: selectedSoftware,
  //   categoryId: categoryId ? categoryId : [],
  //   subCategoryId: subCategoryId1 ? subCategoryId1 : []
  // };

  // const post0 = {
  //   limit: 20,
  //   page: 1,
  //   status: 'approve',
  //   search: searchFilter,
  //   software: selectedSoftware,
  //   categoryId: categoryId ? categoryId : [],
  //   subCategoryId: subCategoryId1 ? subCategoryId1 : []
  // };

  const post2 = {
    limit: 20,
    page: nextPage,
    search: searchFilter,
    status: 'approve',
    software: selectedSoftware,
    categoryId: categoryId,
    subCategoryId: subCategoryId1 ? subCategoryId1 : []
  };

  const post3 = {
    limit: 20,
    page: nextPage,
    search: searchFilter,
    status: 'approve',
    software: selectedSoftware,
    categoryId: [],
    subCategoryId: []
  };

  // const { mutate: getExploredData } = useMutation(
  //   () => ApiPostNoAuth('user/post', post),
  //   {
  //     onSuccess: (res: any) => {

  //       setPostData(res?.data?.data?.post_data);
  //       setPostFirstData(res?.data?.data?.state?.post_count);
  //     }
  //   }
  // );

  const getExploredData = async (softwareTemp?: any, categoryTemp?: any, subCategoryTemp?: any) => {
    let body = {
      limit: 20,
      page: 1,
      status: 'approve',
      search: searchFilter,
      software: softwareTemp?.length ? softwareTemp : selectedSoftware,
      categoryId: categoryTemp?.length ? categoryTemp : categoryId ? categoryId : [],
      subCategoryId: subCategoryTemp?.length ? subCategoryTemp : subCategoryId1 ? subCategoryId1 : []
    };
    await ApiPostNoAuth(`user/post`, body).then((res: any) => {
      setPostData(res?.data?.data?.post_data);
      setPostFirstData(res?.data?.data?.state?.post_count);
    });
  };

  // const { mutate: getExploredDataWithAuth } = useMutation(

  //   async() => await ApiPost('/post', post0),

  //   {

  //     onSuccess: (res: any) => {
  //
  //       setPostData(res?.data?.post_data);
  //       setPostFirstData(res?.data?.state?.post_count);
  //     }
  //   }
  // );

  const { mutate: fetchSubPost } = useMutation(
    () => ApiPostNoAuth('user/post', post2),
    {
      onSuccess: (res: any) => {
        setPostData(res?.data?.data?.post_data);
        setPostFirstData(res?.data?.data?.state?.post_count);
      }
    }
  );

  const { mutate: fetchSoftPost } = useMutation(
    () => ApiPostNoAuth('user/post', post3),
    {
      onSuccess: (res: any) => {
        setPostData(res?.data?.data?.post_data);
        setPostFirstData(res?.data?.data?.state?.post_count);
      }
    }
  );

  const fetchDataLoad = async () => {
    page = page + 1;
    if (postData?.length < 40 || localStorage.getItem('logindata')) {
      const post = {
        limit: 20,
        page: page,
        status: 'approve',
        search: searchFilter,
        software: selectedSoftware,
        categoryId: categoryId ? categoryId : [],
        subCategoryId: subCategoryId1 ? subCategoryId1 : []
      };

      await ApiPostNoAuth('user/post', post)
        .then((res: any) => {
          setPostData([...postData, ...res.data.data.post_data]);
          setPostFirstData(res?.data?.data?.state?.post_count);
        })
        .catch();
    } else {
      dispatch(isOpenModal(true));
    }
  };

  const onFilter = async () => {
    // page = page + 1;
    // const post = {
    //   limit: 20,
    //   page: 1,
    //   status: 'approve',
    //   search: searchFilter,
    //   software: selectedSoftware,
    //   categoryId: categoryId ? categoryId : [],
    //   subCategoryId: subCategoryId1 ? subCategoryId1 : []
    // };

    //  ApiPost('/post', post)
    //   .then((res: any) => {

    //     setPostData(res?.data?.data?.post_data);
    //     setPostFirstData(res?.data?.data?.state?.post_count);
    //   })
    //   .catch((e) => {
    //     //eslint-disable-next-line
    //   });
    userProfileData ? await getExploredDataWithAuth() : await getExploredData();
  };

  const call = async () => {
    await getExploredDataWithAuth();
  };
  const call1 = async () => {
    await getExploredData();
  };



  const postLike = (productId: any) => {
    if (localStorage.getItem('logindata')) {
      ApiGet(`/post/like_post/${productId}`).then(() => {
        setPostData((prevUsers: any) => {
          return prevUsers.map((user: any) => {
            if (user._id === productId) {
              if (user.like) {
                return {
                  ...user,
                  like: false,
                  totalLikes: user.totalLikes - 1
                };
              } else {
                return { ...user, like: true, totalLikes: user.totalLikes + 1 };
              }
            }
            return user;
          });
        });

        // getExploredDataWithAuth();
      });
    } else {
      dispatch(openModal('1'));
      dispatch(isOpenModal(true));
    }
  };
  useQuery(
    'fetchSoftwareData',
    () => ApiGetNoAuth('user/category?software=true'),
    {
      onSuccess: (response: any) => {
        saveSoftwareData(
          response?.data?.data.map((items: any) => {
            return { value: items._id, label: items.name };
          })
        );
      }
    }
  );

  const removeSoftware = (v: any) => {
    let data = softwareValue?.filter((name: any) => name !== v);
    let data2 = selectedSoftware?.filter((name: any) => name !== v.value);
    setsoftwareValue(data);
    page = 1;
    if (softwareId) {
      saveSelectedSoftware([]);
      softwareId = '';
    } else {
      saveSelectedSoftware(data2);
    }
  };

  const removeSoftware2 = (v: any) => {
    let data = categoryValue?.filter((name: any) => name !== v);
    let data2 = selectedCategory?.filter((name: any) => name !== v.value);
    let data3 = categoryId?.filter((name: any) => name !== v.value);
    let data4 = category?.filter((name: any) => name == v);

    setcategoryValue(data);
    setselectedCategory(data2);
    page = 1;

    if (CategoryId) {
      setcategoryId([]);
      CategoryId = '';
    } else {
      setcategoryId(data3);
    }
  };

  const removeSoftware3 = (v: any) => {
    let data = subcategoryValue?.filter((name: any) => name !== v);
    let data2 = subCategoryId1?.filter((name: any) => name !== v.value);
    setsubcategoryValue(data);
    page = 1;

    if (subCategoryId) {
      setsubCategoryId([]);
      subCategoryId = '';
    } else {
      setsubCategoryId(data2);
    }
  };

  useEffect(() => {
    const path: any = location.pathname; // Get the current URL path
    const { title, description, itemH1, itemP, itemSubline }: any = getTitleFromPath(path);
    setDynamicTitle(title);
    setDynamicDescription(description);
    setDynamicH1(itemH1);
    setDynamicSubline(itemSubline);
    setDynamicP(itemP);

    return () => {
      document.title = 'Unicorn UI - Where design begins!';
    };
  }, [location]);

  useEffect(() => {
    async function asyncFunction() {
      const queryObject: any = {
        software: [],
        category: [],
        subCategory: []
      }
      await ApiGetNoAuth('user/sub_category').then((res: any) => {
        setsubCategoryEnums(res?.data?.data?.menu_categories);
        let data456 = res?.data?.data?.menu_categories?.filter((val: any) => {
          if (val?.name === props?.match?.params?.id) {
            return val;
          }
        });
        if (data456.length > 0) {
          queryObject.subCategory = [data456[0]._id];
          setsubcategoryValue([data456[0].name]);
          subCategoryId = data456[0]._id;
        }
      });
      await ApiGet('/category').then((res: any) => {
        setcategory(res?.data?.menu_categories);
        let data123 = res?.data?.menu_categories?.filter((val: any) => {
          if (val?.name === props?.match?.params?.id) { return val; }
        });
        if (data123.length > 0) {
          queryObject.category = [data123[0]._id]
          setcategoryValue([data123[0].name]);
          CategoryId = data123[0]?._id;
        }
        let data12 = res?.data?.software_categories?.filter((val: any) => {
          if (val?.name === props?.match?.params?.id) { return val; }
        });
        if (data12.length > 0) {
          queryObject.software = [data12[0]?._id]
          setsoftwareValue([data12[0].name]);
          saveSelectedSoftware([data12[0]?._id]);
          softwareId = data12[0]?._id;
        }
      });
      getExploredData(queryObject.software, queryObject.category, queryObject.subCategory)
      isReloadUseEffect = true
    }
    asyncFunction()
  }, []);

  React.useEffect(() => {
    if (isReloadUseEffect) {
      onFilter();
    }
  }, [selectedSoftware, categoryValue, subcategoryValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchFilter]);

  useEffect(() => {
    page = 1;
    userProfileData ? call() : call1();
  }, [
    searchFilter,
    nextPage,
    userProfileData,
    selectedSubCategory
  ]);

  return (
    <div className="container section-padding">
      <Helmet>
        {/* <link rel="icon" href="/faviconLight.png" type="image/png" /> */}
        <title>{dynamicTitle}</title>
        <meta name="description" content={dynamicDescription} />
      </Helmet>
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      {postData?.length !== 0 && (
        <div
          className="section-title-wrapper"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="section-title" style={{ marginBottom: '0' }}>
            <h1
              className="gradient-text mobile-font-title"
              style={{ fontSize: '41px' }}
            >
              {dynamicH1}
            </h1>
          </div>
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {dynamicSubline}
          </p>
        </div>
      )}

      {/* -------------------------------------------------- filter start --------------------------------------------------------------- */}

      <div className="filterable-bar mb-10">
        <form id="creators-filter-form">
          <div
            className="filter-options d-flex  justify-content-between w-100 flex-wrap1"
          // style={{ flex: 1 }}
          >
            <div
              // style={{
              //   height: `${softwareValue?.length > 3 ? 'auto' : '51px'}`
              // }}
              className="set-explore"
            >
              <DropDown
                dropdownOpen={softwaredropdownOpen}
                toggle={softwaretoggle}
                categoryValue={softwareValue}
                array={softwareData}
                onClick={handleSoftware}
                multiple={true}
                removeSoftware={removeSoftware}
                placeholder="Select Software"
                isExplore={true}
              />
            </div>

            <div
              // style={{
              //   height: `${categoryValue?.length > 3 ? 'auto' : '51px'}`
              // }}
              className="set-explore"
            >
              <DropDown
                dropdownOpen={dropdownOpen}
                toggle={toggle}
                categoryValue={categoryValue}
                array={category}
                multiple={true}
                isExplore={true}
                onClick={handleOpenCategry}
                removeSoftware={removeSoftware2}
                placeholder="Select Category"
              />
            </div>

            <div
              // style={{
              //   height: `${subcategoryValue?.length > 3 ? 'auto' : '51px'}`
              // }}
              className="set-explorel"
            >
              <DropDown
                dropdownOpen={subdropdownOpen}
                toggle={subtoggle}
                multiple={true}
                isExplore={true}
                categoryValue={subcategoryValue}
                array={subCategoryEnums ?? []}
                onClick={handleOpensubCategry}
                removeSoftware={removeSoftware3}
                placeholder="Select Subcategory"
              />
            </div>
          </div>

          {/* <div className="filter-button">
            <button
              className="btn btn-normal btn-dark"
              type="button"
              onClick={onFilter}
            >
              Filter
            </button>
          </div> */}
        </form>
      </div>
      {/* )} */}
      {/* -------------------------------------------------- filter end --------------------------------------------------------------- */}
      <InfiniteScroll
        dataLength={postData?.length}
        next={fetchDataLoad}
        style={{ overflow: 'hidden' }}
        hasMore={postFirstData > postData?.length}
        endMessage={
          postData?.length !== 0 && (
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          )
        }
        loader={
          <div className="loader">
            {postFirstData !== postData?.length && <Loader />}
          </div>
        }
      >
        <div className="featured-box pe-2">
          {postData?.length ? (
            <div
              className={`featured-box-wrapper  ${postData?.length > 1 ? 'grid-4-columns' : 'grid_25'
                }`}
            >
              {!searchFilter &&
                postData?.length !== 0 &&
                postData?.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Suspense fallback={<div className='d-flex justify-content-center align-items-center'>Loading...</div>}>
                      <ItemCard
                        productId={item._id}
                        totalLikes={item.totalLikes}
                        thumbnail={item.thumbnail}
                        subcategory={item?.subcategory}
                        subCategoryId={item?.subCategoryId}
                        category={item?.category}
                        title={item.title}
                        software={item.software}
                        image={item.user[0]?.image ?? undefined}
                        username={item?.user[0]?.username ?? 'Username'}
                        userId={item?.user[0]?._id}
                        isCreator={item?.user[0]?.isCreator}
                        price={item.price}
                        like={item?.like}
                        postLike={postLike}
                      />
                      </Suspense>
                    </div>
                  );
                })}
              {searchFilter &&
                postData?.length !== 0 &&
                postData?.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Suspense fallback={<div className='d-flex justify-content-center align-items-center'>Loading...</div>}>
                      <ItemCard
                        productId={item._id}
                        totalLikes={item.totalLikes}
                        thumbnail={item.thumbnail}
                        subcategory={item?.subcategory}
                        subCategoryId={item?.subCategoryId}
                        category={item?.category}
                        title={item.title}
                        software={item.software}
                        image={item.user[0]?.image ?? undefined}
                        username={item?.user[0]?.username ?? 'Username'}
                        price={item.price}
                        userId={item?.user[0]?._id}
                        isCreator={item?.user[0]?.isCreator}
                        like={item?.like}
                        postLike={postLike}
                      />
                      </Suspense>
                    </div>
                  );
                })}
            </div>
          ) : (
            <NoDataPage
              title="Result Not Found"
              image="/Image/Icons/NotFound.png"
              desc="Please try again with another keywords or maybe use generic term"
              status={true}
              btnName="Go to Home"
              path="/"
            />
          )}
        </div>
      </InfiniteScroll>
      <div
        className="section-title-wrapper"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className="section-title">
          <h1 className="gradient-text" style={{ fontSize: '25px' }}>
            More
          </h1>
        </div>

        <p
          style={{ fontSize: '14px' }}
          dangerouslySetInnerHTML={{ __html: dynamicP }}
        ></p>
      </div>
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default ExploreItems;
