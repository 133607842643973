import React, { useEffect, useState } from 'react';
import {
  BsInstagram,
  BsPinterest,
  BsYoutube,
  BsLinkedin,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { ApiGetNoAuth, Bucket } from 'src/helpers/API/ApiData';

const Footer = () => {
  const [data, setData] = useState<any>([]);
  const getAdv = async () => {
    ApiGetNoAuth('user/adv').then((res: any) => {
      let dymmy = [];
      let dummyData = [
        {
          image: '642405084c10e35ba8e4c8c6/profile_image/1680516523510.png',
          title: 'Welcome to the Unicorn UI!',
          description: 'Browse the Best Digital Products!'
        }
      ];
      for (let i = 0; i < 1; i++) {
        for (let j = 0; j < res.data.data?.length; j++) {
          dymmy.push(res.data.data[j]);
        }
      }

      if (dymmy.length === 0) {
        setData(dummyData);
      } else {
        setData(dymmy);
      }
    });
  };
  useEffect(() => {
    getAdv();
  }, []);
  const currentPageName = location.pathname;

  return currentPageName !== '/offer' ? (
    <footer className="footer footer-margin">
      <div className="footer-wrapper container">
        <div className="subscribe-widget-v2">
          <div className="animated-img">
            {data?.length === 1 && (
              <img
                src={`${Bucket}${data[0]?.image}`}
                alt="footer poster"
                className="h-100 w-100 rounded-4 object-fit-cover"
                style={{ objectFit: 'cover' }}
                loading='eager'
                width={1268}
                height={193}
              />
            )}
          </div>
          <div className="subscribe-text">
            <div className="h2" style={{ textShadow: '0 0 20px black' }}>
              Join the Creative Community and Earn!
            </div>
            <div
              className="subtitle incres-font"
              style={{ textShadow: '0 0 25px black' }}
            >
              Discover your next design inspiration and unlock new opportunities
              with Unicorn UI and The perfect platform for designers who want to
              earn a living doing what they love
            </div>
          </div>
        </div>

        <div className="footer-content flex-space-between">
          <div className=" footer-column-large">
            <div className="footer-widget">
              <div className="logo">
                <a
                  className="logo-link"
                  // href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/01-index.html"
                  href="#"
                >
                  <div className="new-logo1 logo-img mobile-logo-res">
                    <img src={'/Image/white_logo.png'} alt="logo" loading='lazy' width={500} height={47} />
                  </div>

                </a>
              </div>
              <div className="footer-text">
                <p className="footer-text-pera">
                  Designed to support creators accelerate their workflow. We
                  daily share our updates on these platform 👇
                </p>
              </div>
              <ul className="social-icons-list">

                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://in.pinterest.com/unicornui_official/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsPinterest className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open('https://www.youtube.com/@unicornui', '_blank')
                  }
                >
                  <div>
                    <BsYoutube className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://www.instagram.com/unicornui.official/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsInstagram className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://www.linkedin.com/company/86402313/admin/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsLinkedin className="footerIcon crumina-icon" />
                  </div>
                </li>

              </ul>
            </div>
          </div>

            <div className="footer-widget">
              <div className="widget-title border-gradient">Resources</div>
              <div className="is-two-col-element">
                <ul className="footer-menu">
                  <li className="menu-item">
                    <Link className="menu-link" href="#" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/blog_list">
                      Our Blog
                    </Link>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link" href="/#Creators">
                      Creators
                    </a>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/faq">
                      FAQs
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/contact-us">
                      Contact us
                    </Link>
                  </li>
              </ul>
              </div>
            </div>


            <div className="footer-widget">
              <div className="widget-title border-gradient">Company</div>
              <ul className="footer-menu">
                <li className="menu-item">
                  <Link className="menu-link" to="/about-us">
                    About US
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/license">
                    Unicorn UI Licences
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/terms-of-use">
                    Terms of Use
                  </Link>
                </li>
                <li className="menu-item">
                  <a
                    className="menu-link"
                    href="#"
                  >
                    Career
                  </a>
                </li>
              </ul>
            </div>


            <div className="footer-widget">
              <div className="widget-title border-gradient">Helpful Links</div>
              <ul className="footer-menu">
                <li className="menu-item">
                  <Link className="menu-link" to="/upload" aria-label="Upload your files"
                  >
                    Become a seller
                  </Link>
                </li>

                <li className="menu-item">
                  <Link className="menu-link" to="/plan">
                    Subscription plans
                  </Link>
                </li>

              </ul>
            </div>

        </div>
        <div className="footer-copyright flex-space-between">
          <span className="copyright-text">
            Unicorn UI 2023 - All Rights Reserved
          </span>
        </div>
      </div>
    </footer>
  ) : null;
};

export default Footer;
