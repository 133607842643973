import React from 'react';


const index = () => {
    return (
        <div>
            <div className="become-creator-bc-box container-1300 section-padding-medium">
                <div className="bc-info" style={{ padding: "0" }}>
                    <div className="bc-heading">
                        <div className="bc-heading-subtitle">Welcome to UnicornUI</div>
                        <div className="bc-heading-title" style={{ marginTop: "10px" }}>About <span className="gradient-text">US</span>
                        </div>
                    </div>
                    <div className="bc-description" style={{ marginBottom: "10px" }}>Unicorn UI is well-known digital marketplace for digital assets offering a wide rang of UI kits for web and application, stylish fonts, brand logo, icons and pre-made templates, and you can not only find top-quality design work but also sell your own designs and earn money. Our platform offers a unique opportunity for talented designers to showcase their work and connect with clients looking for high-quality design assets.</div >
                    <div className="bc-description" style={{ marginBottom: "10px" }}>
                        As a seller on our platform, you can upload your designs and set your own pricing. We offer a range of pricing options, from free downloads to premium subscriptions, giving you the flexibility to choose the pricing model that works best for you. Our platform also offers a secure payment system, ensuring that you receive payment for your designs in a timely manner.</div>
                    <div className="bc-description" style={{ marginBottom: "10px" }}>
                        Clients who visit our platform can browse through a vast selection of design assets, from logos and branding materials to website templates and mobile app designs. Our user-friendly interface makes it easy for clients to find the perfect design for their needs, with search filters that allow them to narrow down their search by category, style, and price.</div>
                    <div className="bc-description" style={{ marginBottom: "10px" }}>
                        In addition to selling your designs, our platform also provides a community for designers to connect and collaborate. You can join our design community, share your work, and get feedback from other designers to improve your skills and grow your portfolio.</div>
                    <div className="bc-description" style={{ marginBottom: "10px" }}>
                        We believe that design should be accessible to everyone, which is why we offer a range of pricing options to suit all budgets. With our platform, you can reach a global audience and earn money by sharing your talent with the world. So why wait? Sign up today and start selling your designs on our platform!</div>

                </div>
                <div className="bc-image"><img src="/Image/become-creator.png" alt='image' /></div>
            </div>
            <ins className="adsbygoogle"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-9953424002161141"
                data-ad-slot="9061997256"></ins>
        </div>

    )
}

export default index;