import React, { useState } from 'react';
import moment from 'moment';
import { Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { CgClose } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Aside from '../../components/aside';
import Loader from 'src/components/loader';
import {
  profileUpdate,
  userProfile
} from 'src/redux/reducer/profileUpdateSlice';
import { SuccessToast } from 'src/helpers/Toast';
import NoDataPage from 'src/components/noDataFound/No-data';
import { ApiDelete, ApiPost, } from 'src/services/http-service';

// import Swal from 'sweetalert2';

const Payouts = () => {
  const dispatch = useDispatch();

  const [data, setdata] = React.useState([]);
  const history = useHistory();

 
  const [dropdownOpen, setdropdownOpen] = React.useState<boolean>(false);
  const [Year, setYear] = React.useState<any>('2022');
  const [YearValue, setYearValue] = React.useState<any>('Select Year');
  const [loading, setloading] = React.useState<boolean>(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [totalpage, settotalpage] = useState(0);

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };
  const profileData = useSelector(userProfile);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const handleYear = (e: any) => {
    setYear(e.target.value);
    setYearValue(e.target.innerHTML);
  };
  const deletePlan = () => {
    
    ApiDelete(`/deleteplan/${id}`)
      .then((res: any) => {
       
        localStorage.setItem('planexpire', 'null');
        SuccessToast("Your plan has been successfully deleted!");
        let local = JSON.parse(localStorage.getItem('logindata') || '');
        let data = {
          ...local,
          activeplan: null,
          planexpiry: null
        };
        localStorage.setItem('logindata', JSON.stringify(data));
        localStorage.setItem('planexpire', 'null');
        dispatch(profileUpdate(data));
        // dispatch(profileUpdate(data));
        history.push(`/`);

        getData();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const YearArray = [
    {
      _id: '1',
      name: '2022'
    },
    {
      _id: '2',
      name: '2023'
    },
    {
      _id: '3',
      name: '2024'
    },
    {
      _id: '4',
      name: '2025'
    },
    {
      _id: '5',
      name: '2026'
    }
  ];
  const handleonchnagespagination = (e: any) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
  };
  const onPaginationChange = (e: any, i: any) => {
  
    setcurrentpage(i);
  };
  const getData = () => {
    setloading(true);
    const body = {
      // search: '',
      page: currentpage,
      limit: pagesize
      // year: 2022
    };
    ApiPost('/purchaseplan', body)
      .then((res: any) => {
       
        setdata(res?.data?.plan_data);
        settotalpage(res?.data?.state?.page_limit);
        setloading(false);
      })
      .catch((e) => {
        setloading(false);
      });
  };
  React.useEffect(() => {
    getData();
  }, [currentpage, pagesize]);

  return (
    <div className="primary-content-area container content-padding grid-left-sidebar">
      <Aside />
      <div className="main-content-area">
        <div className="page-title-section">
          <h2>
            <span className="gradient-text">Subscription Details</span>
          </h2>
        </div>
        {/* <div className="filterable-bar">
          <form id="creators-filter-form">
            <div style={{ width: '180px', height: '43px' }}>
              <DropDown
                dropdownOpen={dropdownOpen}
                toggle={toggle}
                categoryValue={YearValue}
                array={YearArray}
                onClick={handleYear}
              />
            </div>
            <div className="filter-button">
              <button className="btn btn-normal btn-dark waves-effect waves-button waves-float waves-light">
                Filter
              </button>
            </div>
          </form>
        </div> */}
        <div className="">
          <div className="">
            <div className="payment-history">
              <h5>Subscription History</h5>
              {loading ? (
                <Loader />
              ) : data?.length !== 0 ? (
                <div>
                  <table className="content-table">
                    <thead>
                      <tr>
                        <th scope="col" className="heading-label">
                          Plan Detail
                        </th>
                        <th scope="col" className="heading-label">
                          Price
                        </th>
                        <th scope="col" className="heading-label">
                          Start Date
                        </th>
                        <th scope="col" className="heading-label">
                          End Date
                        </th>
                        <th scope="col" className="heading-label">
                          Status
                        </th>
                        <th scope="col" className="heading-label">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e: any) => {
                       
                        return (
                          <>
                            <tr>
                              <td data-label="Date">
                                <div className="date">{e?.plan[0]?.title}</div>
                              </td>
                              <td data-label="Method">
                                <div className="item-title">
                                  ${e?.plan[0]?.price ? e?.plan[0]?.price : 0}
                                </div>
                              </td>
                              <td data-label="Amount" className="stat-value">
                                {moment(e?.createdAt).format('MMM DD,YYYY')}
                              </td>
                              <td data-label="price" className="stat-value">
                                {moment(new Date(e?.expireat * 1000)).format(
                                  'MMM DD,YYYY'
                                )}
                              </td>
                              <td>
                                <div
                                  className={
                                    (new Date(e?.expireat * 1000).getTime() <
                                      new Date().getTime()) ||
                                      e.isActive === false
                                      ? 'bg-secondary text-white rounded'
                                      : 'bg-success text-white rounded'
                                  }
                                  style={{
                                    width: 'fit-content',
                                    padding: '0px 8px',
                                    fontSize: '11px',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {/* {e?.isActive ? 'Active' : 'Expired'} */}
                                  {(new Date(e?.expireat * 1000).getTime() <
                                    new Date().getTime()) || e.isActive === false
                                    ? 'Expired'
                                    : 'Active'}
                                </div>
                              </td>
                              {new Date(e?.expireat * 1000).getTime() <
                                new Date().getTime() || e.isActive === false ? (
                                <td className="py-1"></td>
                              ) : (<>
                                <td>
                                <div
                                  className={ 'bg-danger text-white rounded'
                                      
                                  }
                                  onClick={() => {
                                    setModal(true);
                                    setId(e?._id);
                                  }}
                                  style={{
                                    width: 'fit-content',
                                    padding: '0px 8px',
                                    fontSize: '11px',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {/* {e?.isActive ? 'Active' : 'Expired'} */}
                                 DeActive
                                </div>
                              </td>
                                {/* <td className="py-1">
                                  <div className="h-100">
                                    <MdDeleteOutline
                                      className="text-danger d-block cursor-pointer"
                                      style={{ width: '20px', height: '20px' }}
                                      onClick={() => {
                                        setModal(true);
                                        setId(e?._id);
                                      }}
                                    />
                                  </div>
                                </td> */}
                                </>
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  {totalpage > 1 &&
                    <div className="d-flex justify-content-between pt-10">
                      <div className="my-2">
                        <Pagination
                          count={totalpage}
                          page={currentpage}
                          onChange={onPaginationChange}
                          variant="outlined"
                          shape="rounded"
                          className="pagination_"
                        />
                      </div>
                      <div className="my-2">
                        <div className="d-flex align-items-center pagination-drpdown">
                          <select
                            className="form-control pagination-drpdown1 dropdownPage"
                            id="kt_datatable_search_status"
                            onChange={(e) => handleonchnagespagination(e)}
                            value={pagesize}
                          >
                            {/* <option value={1}>1</option> */}
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ) : (
                <NoDataPage
                  title="Result Not Found"
                  image="/Image/Icons/NotFound.png"
                  desc="Please try again with another keywords or maybe use generic term"
                />
              )}
              <ins className="adsbygoogle"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
                data-ad-client="ca-pub-9953424002161141"
                data-ad-slot="4560984194"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
          </div>
          {/* <div className="upload-column">
            <div className="payment-method-section">
              <h5>Payment Method</h5>
              <form className="cryptoki-form" id="payment-method-form">
                <div className="payment-method">
                  <input type="radio" name="payment-method" id="paypal" />
                  <label htmlFor="paypal">Paypal</label>
                  <div className="payment-description">
                    Set up your account to recieve the payouts directly every
                    month!
                  </div>
                </div>
                <div className="payment-method">
                  <input type="radio" name="payment-method" id="credit-card" />
                  <label htmlFor="credit-card">Credit or Debit Card</label>
                </div>
                <button
                  className="btn btn-wide btn-dark waves-effect waves-button waves-float waves-light"
                  type="submit"
                >
                  Confirm Method
                </button>
              </form>
              <div className="confirmed-section">
                <div className="small-title">Confirmed Method</div>
                <div className="confirmed-info">
                  <a
                    href="mailto:dexterstark@fakepay.com"
                    className="green bold"
                  >
                    dexterstark@fakepay.com
                  </a>
                  <div className="confirmed-comment">
                    <span className="bold">Paypal</span> - Confirmed on Nov 17,
                    2020
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

      </div>
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <Dialog
        maxWidth="sm"
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="curveImage" id="style-1">
          <Row style={{ direction: 'rtl' }}>
            <div className="cursor-pointer">
              <CgClose onClick={() => setModal(false)} />
            </div>
          </Row>
          <div className="text-center">
            <img src="Image/Icons/deleteModal.png" width={200} alt='delete' />

            <h5 style={{ marginTop: '-20px' }}>
              Hey {profileData?.name?.split(' ')[0]},
            </h5>

            <p className="mb-4" style={{ fontSize: '17px' }}>
              Are you sure you want to remove this plan.
            </p>

            <div className="d-flex">
              <button
                className="btn btn-fullwidth btn-dark mb-3 saved  "
                type="button"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-fullwidth gradient-background mb-3 ms-3 d-flex justify-content-center "
                type="button"
                onClick={() => deletePlan()}
              >
                Yes, Remove it!
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Payouts;
