import React, { Suspense, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';

const HeroBanner = React.lazy(() => import('./HeroBanner'));
const FeaturedItems = React.lazy(() => import('./FeaturedItems'));
const BrowseCategories = React.lazy(() => import('./BrowseCategories'));
const NewestItems = React.lazy(() => import('./NewestItems'));
const LatestNews = React.lazy(() => import('./LatestNews'));
const HtmlTwoSlider = React.lazy(() => import('./FeaturedSeller'));
const Creative = React.lazy(() => import('./Creative'));

import { getAuth, profileUpdate } from 'src/redux/reducer/profileUpdateSlice';
import { ApiGet } from 'src/helpers/API/ApiData';
import UseNav from 'src/Hooks/Header/UseNav';

// import { profileUpdate } from 'src/redux/reducer/profileUpdateSlice';


const HomePageContainer = () => {
  const navData = UseNav();
  const dispatch = useDispatch();
  const isAuth = useSelector(getAuth);


  const location: any = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      
      location.state?.from && history.push(location.state?.from);
    } else {
      if (history.location.pathname !== '/') {
  
        // dispatch(openModal('1'));
        // dispatch(isOpenModal(true));
      }
    }
  }, [isAuth]);

  const { mutateAsync: profileDataMutate } = useMutation(
    () => ApiGet('/profile'),
    {
      onSuccess: (response: any) => {
          
        let obj = {
          ...response?.data,
          userType: navData?.parsedLoginData?.userType,
          token: navData?.parsedLoginData?.token
        };
        localStorage.setItem('logindata', JSON.stringify(obj));
   
        localStorage.setItem(
          'planexpire',
          JSON.stringify(response?.data?.planexpiry)
        );
        dispatch(profileUpdate(obj));
      }
    }
  );
  useEffect(() => {
    if(isAuth){
      profileDataMutate()

    }
  }, [])

  return (
    <>
      <Suspense fallback={<div className='d-flex justify-content-center align-items-center'>Loading....</div>}>
      <HeroBanner />
      </Suspense>

      <Suspense fallback={<div></div>}>
      <FeaturedItems />
      </Suspense>

      <Suspense fallback={<div></div>}>
      <BrowseCategories />
      </Suspense>

      <Suspense fallback={<div></div>}>
      <NewestItems />
      </Suspense>
      {/* <FeaturedSellers /> */}

      <Suspense fallback={<div></div>}>
      <HtmlTwoSlider />
      </Suspense>

      <Suspense fallback={<div></div>}>
      <LatestNews />
      </Suspense>

      <Suspense fallback={<div></div>}>
      <Creative />
      </Suspense>
    </>
  );
};

export default HomePageContainer;
