import React from 'react';
import '../../assets/css/Style5.css';

const License = (props: any) => {
  return (
    <div>
      <header>
        <div className="header-alignment">
          <div className="left-content">
            <p>Hurry Up! Hot Sale offer</p>
            <img src="/Image/images/above.png" alt="above" />
          </div>
          <div className="center-content">
            <div>
              <h2 id="days">1</h2>
              <span>Days</span>
            </div>
            <div>
              <img src="/Image/images/dot.svg" alt='image' />
            </div>
            <div>
              <h2 id="hours">10</h2>
              <span>Hours</span>
            </div>
            <div>
              <img src="/Image/images/dot.svg" alt='image' />
            </div>
            <div>
              <h2 id="minutes">47</h2>
              <span>Minutes</span>
            </div>
            {/* <div>
              <img src="/Image/images/dot.svg" />
            </div>
            <div>
              <h2 id="seconds"></h2>
              <span>Seconds</span>
            </div> */}
          </div>
          <a
            className="right-content"
            href="https://www.unicornui.com/plan"
            target="_blank"
          >
            <button>Claim Offer Now</button>
          </a>
        </div>
      </header>
      <div className="m-header">
        <div className="header-design">
          <div className="n-text">
            <p>Hurry Up! Hot Sale offer</p>
          </div>
          <div className="m-time">
            <div>
              <h2 id="days1">1</h2>
              <span>Days</span>
            </div>
            <div>
              <img src="/Image/images/m-dot.svg" alt='image' />
            </div>
            <div>
              <h2 id="hours1">10</h2>
              <span>Hours</span>
            </div>
            <div>
              <img src="/Image/images/m-dot.svg" alt='image' />
            </div>
            <div>
              <h2 id="minutes1">47</h2>
              <span>Minutes</span>
            </div>
            {/* <div>
              <img src="/Image/images/m-dot.svg" />
            </div>
            <div>
              <h2 id="seconds1"></h2>
              <span>Seconds</span>
            </div> */}
          </div>
          <div className="m-button">
            <button>Claim Offer Now</button>
          </div>
          <div className="m-round">
            <img src="/Image/images/m-round.svg" alt='image' />
          </div>
        </div>
      </div>
      <div className="hero-section-alignment">
        <div className="hide-all-image">
          <div className="web-template">
            <img src="/Image/images/web-template.png" alt="web-template" />
          </div>
          <div className="icon-text">
            <img src="/Image/images/icon-text.png" alt="icon-text" />
          </div>
          <div className="light-image">
            <img src="/Image/images/light-image.png" alt="light-image" />
          </div>
          <div className="ui-kit">
            <img src="/Image/images/ui-lit.png" alt="ui-lit" />
          </div>
          <div className="mobile-text">
            <img src="/Image/images/mobile-text.png" alt="mobile-text" />
          </div>
          <div className="ui-sec-kit">
            <img src="/Image/images/ui-sec-kit.png" alt="ui-sec-kit" />
          </div>
        </div>
        <div className="container-md">
          <div className="logo-center-alignment">
            <img src="/Image/images/unicorn.svg" alt="unicorn" />
          </div>
          <div className="hero-text">
            <h1>
              Curated <span>1000+ UI Kits</span> to Make Creative Workflow{' '}
              <span>10x Faster</span>
            </h1>
            <p>
              Unleash your creativity by getting unlimited downloads of premium
              UI kits and icons to make designing easy, faster, and reliable.
            </p>
            <a
              className="btn-center"
              href="https://www.unicornui.com/"
              target="_blank"
            >
              <button>Try Now</button>
            </a>
            <div className="center-text">
              <p>
                For only <s style={{ color: 'silver' }}> 15$</s>{' '}
                <span className="gradient-text"> 7$</span> a month
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="text-icon-all-content-alignment">
        <div className="container-lg">
          <div className="grid">
            <div className="grid-items">
              <div className="alignment right-content-alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Sign up for FREE</span>
                </div>
              </div>
              <div className="alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Get your first 10 downloads for FREE</span>
                </div>
              </div>
              <div className="alignment right-content-alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Commercial use allowed on all resources</span>
                </div>
              </div>
              <div className="alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Premium support to premium members</span>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="box">
                <div className="icon">
                  <img src="/Image/images/user.svg" alt="user" />
                </div>
                <span>100% Editable design file</span>
              </div>
              <div className="left-alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Platform for creators to earn money</span>
                </div>
              </div>
              <div className="">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Handpicked industry-wise</span>
                </div>
              </div>
              <div className="left-alignment">
                <div className="box">
                  <div className="icon">
                    <img src="/Image/images/user.svg" alt="user" />
                  </div>
                  <span>Starting at $7. You can cancel anytime</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="card-content-alignment">
        <div className="container-lg">
          <div className="heading">
            <h2>
              One subscription + over 1000 curated assets ={' '}
              <span>Saving time, money & efforts</span>
            </h2>
          </div>
          <div className="grid">
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Fitness%E2%80%93&-Workout-Mobile-App/630e199366910f0e222a7e2d"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1661868407889.jpg"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        658
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Fitness & Workout Mobile App</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/630df2592092730e1f5b562c/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@Syful Islam</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Cryptocurrency%E2%80%93Dashboard-design/6314c223c394030e310373ca"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1662304757740.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        542
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Cryptocurrency Dashboard design</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/630df2592092730e1f5b562c/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@Syful Islam</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Games%E2%80%93Robots-K-VRC-Adventures-App/649ec263af31371156b5ba66"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1688125357521.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        782
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Games Robots K-VRC Adventures App</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/649d5faaa79bda971c105865/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@InfinityLabs</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Virtual%E2%80%93Reality-Services-Landing-Page/64d33bd35ebfdde9a37ff4b7"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1691564874799.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        635
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Virtual Reality Services Landing Page</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/64b64680e030ade07514e0be/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@Shanto</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/NFT%E2%80%93Marketplace-App-Concept/64051a81c55dbd0e1396bc53"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1678056063003.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        254
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>NFT Marketplace App Concept</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/640518a8c55dbd0e1396bb73/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@Max HuaLop</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Artistry%E2%80%93Illustration-Downloading-App-UI/64cba59d86f2149205243cb9"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1691067435564.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        387
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Artistry Illustration Downloading App</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/64b51fe0e030ade07514b961/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@Hetvi Faldu</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Digital%E2%80%93Agency-Website/64a988db19e65b8051399e6b"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1688831656879.png"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        421
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Digital agency website</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/64a66baee3c5ec00ab49b405/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@uisajib</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
            <div className="main-items">
              <div className="grid-items">
                <a
                  href="https://www.unicornui.com/productdetail/Cryptocurrency%E2%80%93Mobile-App/64b8cee366379410c252dd8f"
                  target="_blank"
                >
                  <div className="card-image">
                    <img
                      className="img"
                      src="/Image/images/postimage/1689832919366.jpg"
                      alt='image' 
                    />
                    <div className="button-alignment">
                      <button>SOCIAL GRAPHICS</button>
                    </div>
                    <div className="heart-alignmet">
                      <button>
                        <img src="/Image/images/Heart Icon.svg" alt='image' />
                        235
                      </button>
                    </div>
                  </div>
                  <div className="card-text">
                    <h6>Cryptocurrency Mobile App</h6>
                    <img src="/Image/images/all-icons.png" alt="all-icons" />
                  </div>
                </a>
              </div>
              <div className="sec-card">
                <a
                  className="profile-card"
                  href="https://www.unicornui.com/public/64b638d4e030ade07514dbe8/profile"
                  target="_blank"
                >
                  <img src="/Image/images/Avatar.svg" alt="Avatar" />
                  <span>@shshakil</span>
                </a>
                <h5>FREE</h5>
              </div>
            </div>
          </div>
          <a
            className="btn-center"
            href="https://www.unicornui.com/"
            target="_blank"
          >
            <button>Browse more...</button>
          </a>
        </div>
      </section>
      <section className="price">
        <div className="container-lg">
          <div className="text">
            <p>
              <span>Unicorn UI’s</span> all access subscription gets{' '}
              <span>raving reviews</span> from our subscribers{' '}
            </p>
          </div>
          <div className="slick-slider">
            {/* <div className="element element-1">
              <div className="price-box">
                <div className="cout">
                  <img src="/Image/images/count.svg" alt="count" />
                </div>
                <div className="text-style">
                  <p>
                    Unicorn UI has been a lifesaver in my design career for 5
                    years. It really helpful when I can't find inspiration for
                    my design project, Unicorn UI comes in to save the day and I
                    am thankful for the creators.
                  </p>
                </div>
                <div className="star-alignment">
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                </div>
                <div className="lasttext">
                  <p>
                    <b>- Aizat Hawari</b>
                  </p>
                </div>
              </div>
            </div> */}
            <div className="element element-2">
              <div className="price-box">
                <div className="cout">
                  <img src="/Image/images/count.svg" alt="count" />
                </div>
                <div className="text-style">
                  <p>
                    If I hire designers or freelancers for my design work, they
                    will take more charges but since I joined the membership of
                    Unicorn UI, I have potentially increased my design workflow,
                    saved lots of money, time, and resources. 100% recommended
                    to all.
                  </p>
                </div>
                <div className="star-alignment">
                  <img src="/Image/images/star.svg" alt='image' />
                  <img src="/Image/images/star.svg" alt='image' />
                  <img src="/Image/images/star.svg" alt='image' />
                  <img src="/Image/images/star.svg" alt='image' />
                  <img src="/Image/images/star.svg" alt='image' />
                </div>
                <div className="lasttext">
                  <p>
                    <b>- Jason Lorje.</b>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="element element-3">
              <div className="price-box">
                <div className="cout">
                  <img src="/Image/images/count.svg" alt="count" />
                </div>
                <div className="text-style">
                  <p>
                    The response to my question was quick and understandable as
                    well as the action. The quality of their products is
                    exceptional, and the downloading process is incredibly
                    smooth.
                  </p>
                </div>
                <div className="star-alignment">
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                  <img src="/Image/images/star.svg" />
                </div>
                <div className="lasttext">
                  <p>
                    <b>- Laira ray.</b>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          {/* <!-- <div className="btn-wrap">
                <button className="prev-btn">
                    <img src=".//Image/images/Line 2.svg" alt="">
                </button>
                <button className="next-btn">Next</button> --> */}
        </div>

        {/* </div> */}
      </section>
      <section className="plan">
        <div className="container-lg">
          <div className="heading">
            <h6>
              <span>Choose</span> your plan
            </h6>
            <p>
              Over <span>100,000 downloadable</span> resources, with commercial
              license. Unlimited Downloads and more.
            </p>
          </div>
          <div className="grid">
            <div className="grid-items">
              <div className="btn-align-plan">
                <button>Monthly</button>
              </div>
              <div className="card-two-content-alignment">
                <div className="left-content">
                  <div>
                    <img src="/Image/images/diamond.png" alt='image' />
                  </div>
                  <div>
                    <p>Standard</p>
                    <h6>
                      <span>9</span> service
                    </h6>
                  </div>
                </div>
                <div className="">
                  <h5>$7</h5>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>15 downloads per day </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Advanced Search</p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Valid commercial licenses </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Only one user per account </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>24*7 support </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No ads </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No attribution required </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Good plan for a freelancer and solo designer </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>
                    Free vectors, photos, PSD files, PNG icons, videos, fonts,
                    and 3D models{' '}
                  </p>
                </div>
              </div>
              <a
                className="btn-align"
                href="https://www.unicornui.com/plan"
                target="_blank"
              >
                <button>Choose Plan</button>
              </a>
            </div>
            <div className="grid-items">
              <div className="btn-align-plan">
                <button>Monthly</button>
              </div>
              <div className="card-two-content-alignment">
                <div className="left-content">
                  <div>
                    <img src="/Image/images/diamond.png" alt='image' />
                  </div>
                  <div>
                    <p style={{ color: '#fff' }}>Standard</p>
                    <h6 style={{ color: 'rgba(255, 255, 255, 0.50)' }}>
                      <span style={{ color: '#fff' }}>9</span> service
                    </h6>
                  </div>
                </div>
                <div className="">
                  <h5 style={{ color: '#fff' }}>$20</h5>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>50 downloads per day </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Advanced Search</p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Valid commercial licenses </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Only one user per account</p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>24*7 support </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No ads </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No attribution required </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Good plan for a freelancer and solo designer </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>
                    Free vectors, photos, PSD files, PNG icons, videos, fonts,
                    and 3D models{' '}
                  </p>
                </div>
              </div>
              <a
                className="btn-align"
                href="https://www.unicornui.com/plan"
                target="_blank"
              >
                <button>Choose Plan</button>
              </a>
            </div>
            <div className="grid-items">
              +
              <div className="btn-align-plan">
                <button>Yearly</button>
              </div>
              <div className="card-two-content-alignment">
                <div className="left-content">
                  <div>
                    <img src="/Image/images/diamond.png" alt='image' />
                  </div>
                  <div>
                    <p>Standard</p>
                    <h6>
                      <span>9</span> service
                    </h6>
                  </div>
                </div>
                <div className="">
                  <h5>$200</h5>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>15 downloads per day </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Advanced Search</p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Valid commercial licenses </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Only one user per account </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>24*7 support </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No ads </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>No attribution required </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>Good plan for a freelancer and solo designer </p>
                </div>
              </div>
              <div className="icon-grid">
                <div>
                  <img src="/Image/images/check-true.svg" alt='image' />
                </div>
                <div>
                  <p>
                    Free vectors, photos, PSD files, PNG icons, videos, fonts,
                    and 3D models{' '}
                  </p>
                </div>
              </div>
              <a
                className="btn-align"
                href="https://www.unicornui.com/plan"
                target="_blank"
              >
                <button>Choose Plan</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="faq">
        <div className="container-lg">
          <div className="new-text">
            <p>FAQ's</p>
            <span>
              Whether you are a designer, developer, explorer, or anyone, people
              love us for reasons.
            </span>
          </div>
          <div className="grid">
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>What is Unicorn UI?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    Unicorn UI is the digital marketplace for designers,
                    developers and creative agencies. Where you can find design
                    resources like fonts, illustrations, UI kits, templates,
                    graphics, and more. It also provides creators an opportunity
                    to upload their designs and earn money by selling them.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>How often do you release new products?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    Everyday hundreds of creators upload creative resources on
                    our website and we curate new products on a daily basis. The
                    amount of approved daily releases will vary as our primary
                    focus is quality over quantity.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>Can I get a refund or cancel my subscription?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    Due to the nature of downloadable products, we do not
                    provide refund but you can cancel your subscription at any
                    time from your purchases page and you may continue
                    downloading products until your subscription expires.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>How does licensing on Unicorn UI work?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    The Unicorn UI License gives you the right to use an product
                    purchased from the Unicorn-Ui Store for personal use or as
                    part of a commercial project, for yourself or a third party
                  </p>
                  <p style={{ padding: '0 30px 15px' }}>
                    We provide two types of license:
                  </p>
                  <p style={{ padding: '0 30px 15px' }}>
                    <b>Commercial License:</b> Each purchase allows the buyer to
                    use the product, for one project for commercial use. Please
                    note that end users cannot be charged for this product and
                    you can use it in only one project.
                  </p>
                  <p style={{ padding: '0 30px 30px;' }}>
                    <b>Extended Commercial License:</b> Each purchase allows the
                    buyer to use the product for an unlimited amount of projects
                    for commercial use. This use is limited within the same
                    company.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>Do you offer any discounts?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    We offer the cheapest subscription than other marketplace
                    starting at only 7$ a month. Which means this is the biggest
                    discount we are offering.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>What are the benefits of Unicorn UI Premium?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p style={{ padding: '0 30px 15px' }}>
                    For only $7/month you will gain access to over 1000+ every
                    growing library of UI kits and designs as well as other
                    creative resources.
                  </p>

                  <p style={{ padding: '0 30px 30px' }}>
                    You will also be able to enjoy unlimited downloads of free
                    and premium resources, an AD-free experience while browsing,
                    and the perk of Premium Support.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>What happens when I reach my daily download limit?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    You must wait 24 hours for your downloads to recharge after
                    you’ve exceeded your daily allocated limit. However,
                    upgrading to a higher tier will instantly reset your
                    download credits.
                  </p>
                </div>
              </div>
            </div>
            <div className="collapse-section">
              <div className="grid-items">
                <div className="header-box toggle-button">
                  <p>Can I sell my designs or digital items on Unicorn UI?</p>
                  <img src="/Image/images/down-.svg" alt='image' />
                </div>
                <div className="faq-body collapse-content">
                  <p>
                    Yes, Creators most welcome to Unicorn UI! If you are a
                    creator or designer, you sell your designs, and creative
                    resources on our platform and earn money.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg">
        <div className="container-lg">
          <div className="relative">
            <img src="/Image/images/bg.png" alt='image' />
            <div className="content">
              <div>
                <p>
                  Hurry Up, <span>Special Offer</span> Ends Soon!
                </p>
                <a className="btn-center">
                  <button>Buy Now</button>
                </a>
                <h5>For only 7$ a month</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sub-footer">
        <div className="sub-bg">
          <div className="container-lg">
            <p>JOIN OUR COMMUNITY!</p>
            <a href="https://www.unicornui.com/" target="_blank">
              <img src="/Image/images/text-new.png" alt='image' className="m-hide" />
              <img src="/Image/images/mobile-text0.png" alt='image' className="m-block" />
            </a>
          </div>
        </div>
      </section>
      <footer>
        <div className="container-lg">
          <div className="footer-alignment">
            <div>
              <div className="footer-logo">
                <img src="/Image/images/footer-logo.svg" alt="unicorn" />
              </div>
              <span>
                We are a huge marketplace dedicated to connecting great artists
                of all mediums with their fans and unique token collectors!
              </span>
              <div className="all-social-icons">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  className="footerIcon crumina-icon"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"></path>
                </svg>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  className="footerIcon crumina-icon"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
                </svg>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  className="footerIcon crumina-icon"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                </svg>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  className="footerIcon crumina-icon"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                </svg>
              </div>
            </div>
            <div className="two-content-alignment">
              <div>
                <h6>Unicorn UI Market</h6>
                <div className="two">
                  <div>
                    <a href="https://www.unicornui.com/" target="_blank">
                      Home
                    </a>
                    <a
                      href="https://www.unicornui.com/blog_list"
                      target="_blank"
                    >
                      Our Blog
                    </a>
                    <a>Creators</a>
                    <a href="https://www.unicornui.com/faq" target="_blank">
                      FAQs
                    </a>
                    <a
                      href="https://www.unicornui.com/contact-us"
                      target="_blank"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h6>Company</h6>
                <a href="https://www.unicornui.com/about-us" target="_blank">
                  About us
                </a>
                <a href="https://www.unicornui.com/license" target="_blank">
                  Unicorn UI Licences
                </a>
                <a href="unicornui.com/privacy" target="_blank">
                  Privacy Policy
                </a>
                <a
                  href="https://www.unicornui.com/terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>
                <a>Career</a>
              </div>
              <div>
                <h6>Helpful Links</h6>
                <a href="https://www.unicornui.com/" target="_blank">
                  Become a Seller
                </a>
                <a href="https://www.unicornui.com/plan" target="_blank">
                  Subscription Plans
                </a>
              </div>
            </div>
          </div>
          <div className="copy">
            <span>Unicorn UI 2021 - All Rights Reserved</span>
            <div className="sec">
              <a href="https://www.unicornui.com/terms-of-use" target="_blank">
                Terms of Service
              </a>
              <a href="https://www.unicornui.com/privacy" target="_blank">
                Privacy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default License;
