import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Creator() {
  return (
    <div>
      <div className="become-creator-bc-box pt-0 justify-content-between section-padding-medium">
        <div className="bc-info d-flex flex-column px-0 w-100" style={{flexBasis:"100%"}}>
          <div className="bc-heading">
            <div className="bc-heading-subtitle" style={{marginBottom:"20px"}}>JOIN OUR COMMUNITY!</div>
            <div className="bc-heading-title"  >
            <h1 style={{fontSize:"30px" , marginBottom:"0px" }}>Your profile is currently under review. An email will be sent to you with the result.</h1><span style={{fontSize:"30px" }} className="gradient-text" >Thank you for your submission!</span>
            </div>
          </div>
          <div className="bc-description">
          Please note that your account verification is currently in a pending state. Our team is working diligently to review the provided information and ensure a smooth verification process. We apologize for any inconvenience caused and appreciate your patience.
          </div>
          <div className="bc-button" style={{marginTop:"-20px"}}>
          <Link  to="/benefits"><span className="gradient-text"  style={{fontWeight:"700" , cursor:"pointer"}}>Learn how to get verified and what are the benefits?</span></Link>
            {/* <a className="btn btn-wide btn-dark" href="#application-form">
              Fill the Form
            </a> */}
          </div>
        </div>
        <div className="bc-image w-100" style={{width:"50%"}}>
          <img src="/Image/become-creator.png" alt='image' />
        </div>
      </div>

    </div>
  );
}

export default Creator;
