import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';

function Benefits() {
  return (
    <div>
      <div className="become-creator-bc-box container-1300 section-padding-medium">
        <div className="bc-info">
          <div className="bc-heading">
            <div className="bc-heading-subtitle">JOIN OUR COMMUNITY!</div>
            <div className="bc-heading-title">
              Become a <span className="gradient-text">Creator</span>
            </div>
          </div>
          <div className="bc-description">
            Please note that our verification process is constantly evolving, and we may revisit our decision in the future. In the meantime, we encourage you to continue building your presence on our platform and engaging with our community. Verification is just one aspect of our platform, and there are many other ways to make the most of your experience.
          </div>
        </div>
        <div className="bc-image" style={{ width: "40%" }}>
          <img src="/Image/become-creator.png" alt='image' />
        </div>
      </div>

      <div className="become-creator-bc-box container-1300 section-padding-medium d-flex flex-column pt-0">
        <div className="bc-info d-flex flex-column px-0 w-100" style={{ flexBasis: "100%" }}>
          <div className='mb-3' style={{ fontWeight: "800", fontSize: "19px", paddingLeft: "125px" }}>
            Criterial to get verified on Unicorn UI
          </div>
        </div>
        <div style={{ paddingLeft: "60px" }}>
          <ul style={{ listStyle: 'num' }}>
            <li className="list">
              You need minimum 5 post in your profile to get verified.
            </li>
            <li className="list">
              A complete and professional user profile with a profile picture and description.
            </li>
            <li className="list mt-2">
              A consistent track record of quality design work on Unicorn UI
            </li>
            <li className="list mt-2">
              Submission of original design work that is not plagiarized or infringing upon the intellectual property rights of others.
            </li>
            <li className="list mt-2">
              A high level of engagement with the community, including active and quality submission of the designs.
            </li>
            <li className="list mt-2">
              Compliance with our terms of service, including ethical and legal conduct, respect for intellectual property, and avoidance of spam or fraudulent behaviour.
            </li>
            <li className="list mt-2">
              Verification of personal and professional information, including identity, credentials, and business information, where applicable.
            </li>
            <li className="list mt-2">
              Adherence to any additional criteria or requirements established by our platform from time to time, including subscription or payment requirements for certain design downloads.
            </li>
            <li className="list mt-2">
              No record of unethical or unprofessional behavior in connection with any past design projects.
            </li>
            <li className="list mt-2">
              A strong portfolio of design work that demonstrates skill and creativity.
            </li>
            <li className="list mt-2">
              Clear understanding and acceptance of our platform's terms of service and user agreement.
            </li>
            <li className="list mt-2">
              Compliance with any additional criteria or requirements that may be established by our platform from time to time.
            </li>
          </ul>
        </div>
      </div>

      <div className="become-creator-bc-box container-1300 section-padding-medium d-flex flex-column pt-0">
        <div className="bc-info d-flex flex-column px-0 w-100" style={{ flexBasis: "100%" }}>
          <div className='mb-3' style={{ fontWeight: "800", fontSize: "19px", paddingLeft: "120px" }}>
            Benefits to getting verified on a Unicorn UI
          </div>
        </div>
        <div style={{ marginLeft: "-60px" }}>
          <ul style={{ listStyle: 'num' }}>
            <li className="list">
              You will receive a share of revenue generated from the number of your Premium Resources downloaded
            </li>
            <li className="list mt-2">
              Increased visibility and exposure to potential customers seeking design services.
            </li>
            <li className="list mt-2">
              Access to a wider range of design project opportunities, including premium projects that require a subscription or payment to access.
            </li>
            <li className="list mt-2">
              The ability to set higher pricing for design work, resulting in increased earnings potential.
            </li>
            <li className="list mt-2">
              Access to a community of fellow designers and industry experts, with opportunities to collaborate and learn from others.
            </li>
            <li className="list mt-2">
              Recognition and validation of your design skills and expertise by the platform and customers alike.
            </li>
            <li className="list mt-2">
              Increased credibility and trustworthiness in the eyes of potential clients, leading to more design work and repeat business.
            </li>
            <li className="list mt-2">
              Access to exclusive resources, such as tutorials, design tools, and industry insights, to help improve your design skills and business acumen.
            </li>
            <li className="list mt-2">
              Higher search engine rankings and visibility on the platform, making it easier for potential customers to find your profile and work.
            </li>
            <li className="list mt-2">
              Access to detailed analytics and performance metrics, such as download and sales data, to track your progress and improve your strategies.
            </li>
            <li className="list mt-2">
              Increased brand exposure and recognition, as your designs become more widely recognized and shared.
            </li>
            <li className="list mt-2">
              A higher level of customer support and assistance from the platform, including technical and marketing support.                  </li>
            <li className="list mt-2">
              Access to promotional opportunities, such as featured profiles or design showcases, to increase your visibility and reach.                  </li>
            <li className="list mt-2">
              The ability to build long-term relationships with customers and establish a loyal client base.                  </li>
            <li className="list mt-2">
              Increased control over your design projects and workflow, including the ability to set your own project parameters and timelines.                  </li>
            <li className="list mt-2">
              Access to a wider range of design categories and project types, allowing you to explore new areas of design and expand your skill set.                  </li>
            <li className="list mt-2">
              The ability to build a portfolio of diverse and high-quality design work that can be used to showcase your skills and attract new clients.                  </li>
            <li className="list mt-2">
              Access to a variety of payment options and withdrawal methods, making it easier to receive and manage your earnings.                  </li>
            <li className="list mt-2">
              The opportunity to network and collaborate with other designers and industry professionals, leading to potential partnerships and new business opportunities.                  </li>
            <li className="list mt-2">
              Increased exposure to potential employers and job opportunities outside of the platform, as your verified status demonstrates your credibility and skill level.                  </li>
            <li className="list mt-2">
              Access to a community of like-minded professionals who share your passion for design and creativity.                  </li>
            <li className="list mt-2">
              The ability to work from anywhere, as long as you have access to a computer and internet connection.                  </li>
            <li className="list mt-2">
              The opportunity to work on a flexible schedule that fits your lifestyle and preferences.                  </li>
            <li className="list mt-2">
              The ability to choose the projects and clients that best align with your skills and interests.                  </li>
            <li className="list mt-2">
              The opportunity to learn and grow as a designer by receiving feedback and constructive criticism from customers and other designers.
            </li>
            <li className="list mt-2">
              Access to customer feedback and ratings, which can help you identify areas for improvement and refine your design skills.                  </li>
            <li className="list mt-2">
              Increased autonomy and control over your career, as you can set your own goals and priorities.                  </li>
            <li className="list mt-2">
              The opportunity to build a reputation as a respected and sought-after designer within the industry.
            </li>
            <li className="list mt-2">
              The ability to leverage your design work into additional revenue streams, such as selling design assets or launching your own design business.
            </li>
            <li className="list mt-2">
              The potential for ongoing residual income from design work that continues to sell and generate revenue over time.
            </li>
            <li className="list mt-2">
              The ability to make a positive impact on the world through design, by creating solutions that solve real-world problems and enhance people's lives.                  </li>
          </ul>
        </div>
      </div>

    </div>
  );
}

export default Benefits;
