import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Bucket } from 'src/services/http-service';
import { ApiGetNoAuth, ApiPostNoAuth } from 'src/helpers/API/ApiData';

const Blog_List = () => {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [categoryData, setcategoryData] = React.useState([]);
  
  React.useEffect(() => {
    const body = {
      search: '',
      page: 1,
      limit: 1000
    };
    ApiPostNoAuth('user/blog/get', body).then((res: any) => {
      setData(res?.data?.data?.blog_data);
    });

    ApiGetNoAuth('user/category/get?blog=true').then((res: any) => {
      setcategoryData(res?.data?.data);
    });
  }, []);

  const blogDetails = (q: any) => {
    history.push({
      pathname: '/blog_details',
      state: q
    });
  };

  return (
    <div className="primary-content-area container content-padding">
      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <div className="page-title-section">
        <h2>
          Our<span className="gradient-text"> Blog</span>
        </h2>
      </div>
      <div className="blog-classNameic-area grid-right-sidebar-large">
        <div className="blog-list">
          {data.map((e: any) => {
            return (
              <div className="news-item-classNameic">
                <div className="news-thumb" onClick={() => blogDetails(e)} style={{ cursor: 'pointer' }}>

                    {e?.URLs[0]?.includes('.mp4') ? (
                      <video
                      src={Bucket + e?.URLs[0]}
                        style={{ height: '100%', objectFit: 'cover' }}
                      ></video>
                    ) : (
                      <img
                        src={Bucket + e?.URLs[0]}
                          alt='image' 
                        style={{ height: '100%', objectFit: 'cover' }}
                      />
                  )}
                </div>
                <div className="news-content">
                  <div className="news-tags">
                    <span className="tag-item">
                      {e?.category[0]?.name}
                    </span>
                  </div>
                  <div className="news-title h5">
                    <span onClick={() => blogDetails(e)}
                      style={{ cursor: 'pointer' }}
                      className="news-title-in">
                      {e?.title}
                    </span>
                  </div>

                  <div className="news-meta">
                    by <span>Unicorn UI</span>,{' '}
                    {moment(e?.createdAt).format('MMMM DD,YYYY')}
                  </div>
                  <div className="archive-item">
                    <span
                      onClick={() => blogDetails(e)}
                      className="read-more-link fw_800"
                      style={{ cursor: 'pointer' }}
                    >
                      Read More
                      <svg className="crumina-icon"></svg>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <aside>
          <div className="widgets-list">
            <div className="widget-2 recent-posts-widget">
              <div className="widget-title h5">Latest News</div>
              <div className="widget-body">
                {data.map((e: any, i: any) => {
                  return (
                    i <= 2 && (
                      <div className="recent-posts-widget-item" style={{ cursor: 'pointer' }}>
                        <div className="recent-posts-widget-item-img">
                          <span onClick={() => blogDetails(e)}>
                            <img
                              src={Bucket + e?.URLs[0]}
                              alt='image' 
                              style={{ height: '100%' }}
                            />
                          </span>
                        </div>
                        <div className="recent-posts-widget-item-info">
                          <div className="recent-posts-widget-item-tags">
                            <span className="tag-item" >
                              {e?.category[0]?.name}
                            </span>
                          </div>
                          <div className="recent-posts-widget-item-title">
                            <span onClick={() => blogDetails(e)}>{e?.title}</span>
                          </div>
                          <div className="recent-posts-widget-item-meta">
                            by <span>Unicorn UI</span>,{' '}
                            {moment(e?.createdAt).format('MMMM DD,YYYY')}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>

            <div className="widget-2 categories-widget">
              <div className="widget-title h5">Categories</div>
              <div className="widget-body">
                <div className="categories-widget-list">
                  {categoryData?.map((e: any) => {
                    return (
                      <a href="#" className="categories-widget-item">
                        {e?.name}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <ins className="adsbygoogle"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: "20px" }}
              data-ad-format="fluid"
              data-ad-layout-key="-67+bz+t-13+i9"
              data-ad-client="ca-pub-9953424002161141"
              data-ad-slot="6975394544"></ins>

          </div>
        </aside>
      </div>

      <ins className="adsbygoogle"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: "10px" }}
        data-ad-client="ca-pub-9953424002161141"
        data-ad-slot="4560984194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default Blog_List;
